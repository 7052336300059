import React from 'react';
import { useSelector } from 'react-redux';

import * as styles from './styles.module.css';
import loading from '../../../../assets/icons/loading.png';

import {
  QUEUE_DEFAULT_POSITION,
  QUEUE_PARAMETER,
  MESSAGE_TYPE_QUEUE_FINISHED,
  MESSAGE_TYPE_QUEUE,
} from '../../../../constants';

const Queued = props => {
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const queuedMessage = props.messages.filter(m => m.type === MESSAGE_TYPE_QUEUE_FINISHED).length === 0 
                        ? props.messages.filter(m => m.type === MESSAGE_TYPE_QUEUE).slice(-1) : null;

  function getPositionValue(position) {
    return (position === QUEUE_PARAMETER ? QUEUE_DEFAULT_POSITION : position);
  }
  
  function getMessage(m) {
    if (!m.content) {
      return;
    }

    const message = m.content.replace('{{','|').replace('}}', '|');
    const position = message.match(/\|([^)]+)\|/) ? message.match(/\|([^)]+)\|/).pop() : null;
       
    return message.split("|").map((val, index) => {
      return (
        val === position
          ? <span key={index}className={styles.position} style={{ color: baseColor }}>{getPositionValue(position)}</span> 
          : <span key={index} className={styles.message}>{val}</span>        
      )
    });
  }

  return (
    queuedMessage ? queuedMessage.map((m, index) => (
        <div className={styles.queueMessage} key={index}>
          <img className={styles.image} src={loading} alt="Carregando" />
          <div className={styles.messageArea}>{getMessage(m)}</div>      
        </div>
    )
  ) : null);
};

export default Queued;
