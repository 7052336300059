import { configReducer } from './configReducer';
import { chatReducer } from './chatReducer';
import { appReducer } from './appReducer';

import { combineReducers } from 'redux';
export const Reducers = combineReducers({
  configState: configReducer,
  chatState: chatReducer,
  appState: appReducer,
});
