export const MESSAGE_TYPE_TEXT = 'text';
export const MESSAGE_TYPE_OPEN_QUESTION = 'open-question';
export const MESSAGE_TYPE_ALTERNATIVE_QUESTION = 'alternative-question';
export const MESSAGE_TYPE_FILES = 'files';
export const MESSAGE_TYPE_ATTACHMENT = 'attachment';
export const MESSAGE_TYPE_START = 'start';
export const MESSAGE_TYPE_CANCEL = 'cancel';
export const MESSAGE_TYPE_FINALIZATION = 'finalization';
export const MESSAGE_TYPE_FINISHED = 'finished';
export const MESSAGE_TYPE_RECONNECT = 'reconnect';
export const MESSAGE_TYPE_QUEUE = 'queue_type';
export const MESSAGE_TYPE_QUEUE_FINISHED = 'queue_finished';

export const CONVERSATION_HISTORY_TRIGGER_POSITION = 50;
export const CONVERSATION_HISTORY_THROTTLE_DELAY = 500;
export const CONVERSATION_HISTORY_AMOUNT = 10;

export const FLOW_CANCELLED_MESSAGE = 'Atendimento finalizado pelo usuário';
export const FLOW_FINISHED_MESSAGE = 'Atendimento finalizado';

export const QUEUE_DEFAULT_POSITION = '10ᵒ';
export const QUEUE_PARAMETER = "posicao";

export const PAGE_AUTH = "auth";
export const PAGE_CONVERSATION = "conversation";
export const PAGE_QUEUE = "queue";
export const PAGE_TERMS_OF_USE = "termsOfUse";

export const PAGE_HAS_TERMS_OF_US = "hasTermsOfUs";

export const FORM_METADATA_CACHE_NAME = "connectChatterParams";
