import {
  UPDATE_HAS_TERMS_OF_USE,
  UPDATE_TERMS_OF_USE,
  UPDATE_TERMS_OF_USE_ID,
  UPDATE_TERMS_OF_USE_TITLE,
  UPDATE_TERMS_OF_USE_TYPE,
} from "../../domain/TermsOfUse/actionTypes";
import chatConfig from "../../services/chatConfig";
import {
  UPDATE_PREVIEWPAGE,
  UPDATE_MESSAGEQUEUE,
  UPDATE_BASE_COLOR,
  UPDATE_TITLE,
  UPDATE_NAME,
  UPDATE_DESCRIPTION,
  UPDATE_IMG192,
  UPDATE_IMG512,
  UPDATE_FOOTER_TEXT,
  UPDATE_FOOTER_URL,
  UPDATE_SHOW_FORM_DRAW,
  UPDATE_CONFIGS,
  UPDATE_FORM_FIELDS,
  UPDATE_FORM_SUBMITTED,
  UPDATE_ERROR_FETCHING_CONFIG,
  RESET_FORM,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  isWidget: chatConfig.isWidget,
  isDraw: chatConfig.isDraw,
  chatter: {
    channelId: chatConfig.chatter.channelId,
    token: chatConfig.chatter.token,
  },
  styles: {
    title: chatConfig.styles.title,
    description: chatConfig.styles.description,
    footerText: chatConfig.styles.footerText,
    name: chatConfig.styles.name,
    footerUrl: chatConfig.styles.footerUrl,
    img192: chatConfig.styles.img192,
    img512: chatConfig.styles.img512,
    baseColor: chatConfig.styles.baseColor,
    foregroundColor: chatConfig.styles.foregroundColor,
  },
  form: {
    fields: chatConfig.form.fields,
    showFormMessage: chatConfig.form.showFormMessage,
    submitted: chatConfig.form.submitted,
  },
  globalTriggers: chatConfig.globalTriggers,
  showTyping: chatConfig.showTyping,
  showFormDraw: false,
  previewPage: null,
  queueMessage: "",
  termsOfUseId: null,
  termsOfUseType: null,
  termsOfUseTitle: "",
  termsOfUse: "",
  ready: chatConfig.ready,
  errorFetchingConfig: false,
};

export const configReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PREVIEWPAGE:
      return {
        ...state,
        previewPage: action.previewPage,
      };
    case UPDATE_MESSAGEQUEUE:
      return {
        ...state,
        queueMessage: action.queueMessage,
      };

    case UPDATE_HAS_TERMS_OF_USE:
      return {
        ...state,
        hasTermsOfUse: action.hasTermsOfUse,
      };

    case UPDATE_TERMS_OF_USE:
      return {
        ...state,
        termsOfUse: action.termsOfUse,
      };

    case UPDATE_TERMS_OF_USE_ID:
      return {
        ...state,
        termsOfUseId: action.termsOfUseId,
      };

    case UPDATE_TERMS_OF_USE_TITLE:
      return {
        ...state,
        termsOfUseTitle: action.termsOfUseTitle,
      };

    case UPDATE_TERMS_OF_USE_TYPE:
      return {
        ...state,
        termsOfUseType: action.termsOfUseType,
      };

    case UPDATE_BASE_COLOR:
      return {
        ...state,
        styles: {
          ...state.styles,
          baseColor: action.baseColor,
          foregroundColor: chatConfig.styles.foregroundColor,
        },
      };
    case UPDATE_TITLE:
      return {
        ...state,
        styles: {
          ...state.styles,
          title: action.title,
        },
      };
    case UPDATE_NAME:
      return {
        ...state,
        styles: {
          ...state.styles,
          name: action.name,
        },
      };
    case UPDATE_DESCRIPTION:
      return {
        ...state,
        styles: {
          ...state.styles,
          description: action.description,
        },
      };
    case UPDATE_IMG192:
      return {
        ...state,
        styles: {
          ...state.styles,
          img192: action.img192,
        },
      };
    case UPDATE_IMG512:
      return {
        ...state,
        styles: {
          ...state.styles,
          img512: action.img512,
        },
      };
    case UPDATE_FOOTER_TEXT:
      return {
        ...state,
        styles: {
          ...state.styles,
          footerText: action.footerText,
        },
      };
    case UPDATE_FOOTER_URL:
      return {
        ...state,
        styles: {
          ...state.styles,
          footerUrl: action.footerUrl,
        },
      };
    case UPDATE_SHOW_FORM_DRAW:
      return {
        ...state,
        showFormDraw: action.showFormDraw,
      };
    case UPDATE_CONFIGS:
      return {
        ...state,
        ...action.configs,
      };
    case UPDATE_FORM_FIELDS:
      return {
        ...state,
        form: {
          fields: action.formFields,
          showFormMessage: state.form.showFormMessage,
          submitted: state.form.submitted,
        },
      };
    case UPDATE_FORM_SUBMITTED:
      return {
        ...state,
        form: {
          fields: state.form.fields,
          showFormMessage: state.form.showFormMessage,
          submitted: action.formSubmitted,
        },
      };
    case RESET_FORM:
      return {
        ...state,
        form: {
          fields:
            state.form.fields &&
            state.form.fields.map((field) => {
              return { ...field, value: "", error: false };
            }),
          showFormMessage: state.form.showFormMessage,
          submitted: false,
        },
      };
    case UPDATE_ERROR_FETCHING_CONFIG:
      return {
        ...state,
        errorFetchingConfig: action.errorFetchingConfig,
      };
    default:
      return state;
  }
};
