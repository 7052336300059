import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { sendMessage } from '../../../../store/actions/chatActions';

import styles from './styles.module.css';

const GlobalTriggerButton = props => {
  const dispatch = useDispatch();
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);
  const globalTriggers = useSelector(state => state.configState.globalTriggers);
  const [showMenu, setShowMenu] = useState(false);

  const handlerGlobalTriggerButton = (msg) => {
    dispatch(sendMessage(msg));
    setShowMenu(false);
  };

  const handlerExitButton = () => {
    props.onExitButton();
    setShowMenu(false);
  };

  const globalTriggersItems = globalTriggers.map(m => {
    return (
      <button key={m} style={{color: foregroundColor}} onClick={() => handlerGlobalTriggerButton(m)}>{m}</button>
    );
  });

  const renderMenuDropdown = () => {
    if (showMenu) {
      return (
        <div className={styles.dropdown} style={{backgroundColor: baseColor}}>
          {globalTriggersItems}
          <hr />
          <button key="exit" onClick={handlerExitButton} style={{color: foregroundColor}}>Sair</button>
        </div>
      );
    }

    return null;
  };

  const handleButtonClick = e => {
    e.preventDefault();

    setShowMenu(!showMenu);
  };

  return (
    <>
      <button className={styles.globalTriggerButton} onClick={handleButtonClick}>
        {props.icon}
      </button>
      {renderMenuDropdown()}
    </>
  );
};

GlobalTriggerButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onExitButton: PropTypes.func.isRequired,
};

export default React.memo(GlobalTriggerButton);
