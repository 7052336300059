import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrin } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../../../layout/Header/Button';
import { i18n } from './emojiPickerConfig';
import { handleMessageInputChange, toggleEmojiPickerVisibility } from '../../../../store/actions/chatActions';

import "emoji-mart/css/emoji-mart.css";

import styles from './styles.module.css';

const EmojiPicker = (props) => {
  const dispatch = useDispatch();
  const showEmojiPicker = useSelector(state => state.chatState.showEmojiPicker);

  const handleInputEmoji = (e) => {
    if (props.setEmojiInto && props.setEmojiInto.current && props.setEmojiInto.current.value !== undefined) {
      const cursorStart = props.setEmojiInto.current.selectionStart;
      const cursorEnd = props.setEmojiInto.current.selectionEnd;
      props.setEmojiInto.current.value = props.setEmojiInto.current.value.substr(0, cursorStart) + e.native + props.setEmojiInto.current.value.substr(cursorEnd);
      props.setEmojiInto.current.setSelectionRange(cursorStart+2, cursorStart+2);
      props.setEmojiInto.current.focus();

      dispatch(handleMessageInputChange({target: { value: props.setEmojiInto.current.value } }));
    }
    dispatch(toggleEmojiPickerVisibility(false));
  };

  return (
    <>
      <ButtonIcon
        icon={<FontAwesomeIcon className={styles.emojiPickerIcon} icon={faGrin} />}
        onClick={() => {dispatch(toggleEmojiPickerVisibility(!showEmojiPicker));} }
      />
      <div className={styles.emojiPicker}>
        <Picker
          // set='twitter' // set de emoji que será utilizado, caso não seja native
          onClick={handleInputEmoji}
          emoji={null} // emoji do footer
          title={null} // titulo do footer
          showPreview={false} // preview no footer
          showSkinTones={false} // bolinha de seleção de skin tone no footer
          skinEmoji='hand' // emoji que será utilizado para a seleção do skin tone
          notFoundEmoji='sleuth_or_spy'
          // exclude={['recent']} // exclusão de categoria
          // emojisToShowFilter={config -> emojisToShowFilter} // filtro dos emojis que serão mostrados
          // sheetSize={32} // qualidade do emoji
          native={true} // emoji unicode
          style={!showEmojiPicker ? {display: 'none'} : {}}
          i18n={i18n}
        />
      </div>
    </>
    );
};

EmojiPicker.propTypes = {
  setEmojiInto: PropTypes.object,
};

export default React.memo(EmojiPicker);
