const foregroundColor = (backgroundColor) => {
  if (backgroundColor.indexOf('#') === 0) {
    backgroundColor = backgroundColor.slice(1);
  }
  if (backgroundColor.length === 3) {
    backgroundColor =
      backgroundColor[0] +
      backgroundColor[0] +
      backgroundColor[1] +
      backgroundColor[1] +
      backgroundColor[2] +
      backgroundColor[2];
  }
  if (backgroundColor.length !== 6) {
    throw new Error('Invalid color.');
  }
  const r = parseInt(backgroundColor.slice(0, 2), 16);
  const g = parseInt(backgroundColor.slice(2, 4), 16);
  const b = parseInt(backgroundColor.slice(4, 6), 16);
  return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
};

export default foregroundColor;
