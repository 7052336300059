import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.module.css';

const UserInputFile = props => {
  return (
    <React.Fragment>
      <label htmlFor="input-file" className={styles.inputFileIcon}>
        <FontAwesomeIcon icon={faPaperclip} />
      </label>

      <input
        id="input-file"
        name="input-file"
        type="file"
        accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf,video/mp4"
        onChange={props.sendAttachment}
        className={styles.inputFile}
      />
    </React.Fragment>
  );
};

UserInputFile.propTypes = {
  sendAttachment: PropTypes.func.isRequired,
};

export default React.memo(UserInputFile);
