import React from 'react';
import PropTypes from 'prop-types';

const ButtonImage = props => {
  return (
    <img
      data-testid="baseButtonImage"
      src={require(`../../../../../assets/icons/${props.imageName}.svg`)}
      alt={props.imageName}
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    />
  );
};

ButtonImage.propTypes = {
  imageName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default React.memo(ButtonImage);
