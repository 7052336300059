import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserInputButtons from './Buttons';
import UserInputMessage from './Message';
import UserInputFile from './InputFile';
import EmojiPicker from './EmojiPicker';
import { MESSAGE_TYPE_ALTERNATIVE_QUESTION, MESSAGE_TYPE_FINALIZATION, MESSAGE_TYPE_FINISHED } from '../../../constants';
import { sendAttachment, showSendingFile } from '../../../store/actions/chatActions';

import styles from './styles.module.css';

const UserInput = () => {
  const dispatch = useDispatch();
  const messageType = useSelector(state => state.chatState.messageType);
  const isDraw = useSelector(state => state.configState.isDraw);
  const userInputRef = useRef(null);
  let userInputRender = '';

  if (messageType === MESSAGE_TYPE_ALTERNATIVE_QUESTION) {
    userInputRender = (
      <UserInputButtons />
    );
  } else if (messageType !== MESSAGE_TYPE_FINALIZATION && messageType !== MESSAGE_TYPE_FINISHED) {
    userInputRender = (
      <div className={styles.userInputContainer} style={isDraw ? {display: 'none'} : {}}>
        <UserInputFile sendAttachment={handlerChange} />

        <EmojiPicker setEmojiInto={userInputRef} />

        <UserInputMessage inputRef={userInputRef} />
      </div>
    );
  }

  function handlerChange(e) {
    e.preventDefault();
    dispatch(showSendingFile(true));
    dispatch(sendAttachment(e));
  }

  return(
    <div className={styles.userInput}>
      {userInputRender}
    </div>
  );
};

export default UserInput;
