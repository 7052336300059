import {
  UPDATE_HAS_TERMS_OF_USE,
  UPDATE_TERMS_OF_USE,
  UPDATE_TERMS_OF_USE_ID,
  UPDATE_TERMS_OF_USE_TITLE,
  UPDATE_TERMS_OF_USE_TYPE,
} from "../../domain/TermsOfUse/actionTypes";
import {
  UPDATE_PREVIEWPAGE,
  UPDATE_MESSAGEQUEUE,
  UPDATE_BASE_COLOR,
  UPDATE_TITLE,
  UPDATE_NAME,
  UPDATE_DESCRIPTION,
  UPDATE_IMG192,
  UPDATE_IMG512,
  UPDATE_FOOTER_TEXT,
  UPDATE_FOOTER_URL,
  UPDATE_CONFIGS,
  UPDATE_FORM_FIELDS,
  UPDATE_FORM_SUBMITTED,
  RESET_FORM,
  UPDATE_SHOW_FORM_DRAW,
  UPDATE_ERROR_FETCHING_CONFIG,
} from "./actionTypes";

const updatePreviewPage = (previewPage) => {
  return {
    type: UPDATE_PREVIEWPAGE,
    previewPage,
  };
};

const updateQueueMessage = (queueMessage) => {
  return {
    type: UPDATE_MESSAGEQUEUE,
    queueMessage,
  };
};

const updateHasTermsOfUse = (hasTermsOfUse) => {
  return {
    type: UPDATE_HAS_TERMS_OF_USE,
    hasTermsOfUse,
  };
};

const updateTermsOfUseId = (termsOfUseId) => {
  return {
    type: UPDATE_TERMS_OF_USE_ID,
    termsOfUseId,
  };
};

const updateTermsOfUseType = (termsOfUseType) => {
  return {
    type: UPDATE_TERMS_OF_USE_TYPE,
    termsOfUseType,
  };
};

const updateTermsOfUseTitle = (termsOfUseTitle) => {
  return {
    type: UPDATE_TERMS_OF_USE_TITLE,
    termsOfUseTitle,
  };
};

const updateTermsOfUse = (termsOfUse) => {
  return {
    type: UPDATE_TERMS_OF_USE,
    termsOfUse,
  };
};

const updateBaseColor = (baseColor) => {
  return {
    type: UPDATE_BASE_COLOR,
    baseColor,
  };
};

const updateTitle = (title) => {
  return {
    type: UPDATE_TITLE,
    title,
  };
};

const updateName = (name) => {
  return {
    type: UPDATE_NAME,
    name,
  };
};

const updateDescription = (description) => {
  return {
    type: UPDATE_DESCRIPTION,
    description,
  };
};

const updateImg192 = (img192) => {
  return {
    type: UPDATE_IMG192,
    img192,
  };
};

const updateImg512 = (img512) => {
  return {
    type: UPDATE_IMG512,
    img512,
  };
};

const updateFooterText = (footerText) => {
  return {
    type: UPDATE_FOOTER_TEXT,
    footerText,
  };
};

const updateFooterUrl = (footerUrl) => {
  return {
    type: UPDATE_FOOTER_URL,
    footerUrl,
  };
};

const updateShowFormDraw = (showFormDraw) => {
  return {
    type: UPDATE_SHOW_FORM_DRAW,
    showFormDraw,
  };
};

const updateConfigs = (configs) => {
  return {
    type: UPDATE_CONFIGS,
    configs,
  };
};

const updateFormFields = (formFields) => {
  return {
    type: UPDATE_FORM_FIELDS,
    formFields,
  };
};

const updateFormSubmitted = (formSubmitted) => {
  return {
    type: UPDATE_FORM_SUBMITTED,
    formSubmitted,
  };
};

const resetForm = () => {
  return {
    type: RESET_FORM,
  };
};

const updateErrorFetchingConfig = (errorFetchingConfig) => {
  return {
    type: UPDATE_ERROR_FETCHING_CONFIG,
    errorFetchingConfig,
  };
};

export {
  updateBaseColor,
  updateTitle,
  updateName,
  updateDescription,
  updateImg192,
  updateImg512,
  updateFooterText,
  updateFooterUrl,
  updateShowFormDraw,
  updateConfigs,
  updateFormFields,
  updateFormSubmitted,
  updatePreviewPage,
  updateQueueMessage,
  updateHasTermsOfUse,
  updateTermsOfUseId,
  updateTermsOfUseType,
  updateTermsOfUseTitle,
  updateTermsOfUse,
  resetForm,
  updateErrorFetchingConfig,
};
