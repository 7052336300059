import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import 'animate.css';
import Header from './components/layout/Header';
import CompanyHeader from './components/Chat/Company';
import Welcome from './components/Welcome';
import Chat from './components/Chat';
import Queue from './components/Queue';
import TermsOfUse from "./components/TermsOfUse";
import Footer from './components/layout/Footer';
import Dialog from './components/Dialog';
import Loading from './components/Loading';
import FetchingErrorMessage from './components/FetchingErrorMessage';

import { handlerLoading } from './store/actions/appActions';

import {
  PAGE_AUTH,
  PAGE_CONVERSATION,
  PAGE_QUEUE,
  PAGE_TERMS_OF_USE
} from './constants';

const App = () => {
  const dispatch = useDispatch();
  const ready = useSelector(state => state.configState.ready);
  const errorFetchingConfig = useSelector(state => state.configState.errorFetchingConfig);
  const renderCompanyHeader = useSelector(state => !!state.configState.styles.name);
  const showChat = useSelector(state => state.chatState.showChat);
  const showTerms = useSelector((state) => state.chatState.showTerms);
  const previewPage = useSelector(state => state.configState.previewPage);
  
  useEffect(() => {
    if (ready) {
      dispatch(handlerLoading(false));
    }
  }, [ready, dispatch]);

  function loadBody() {
    if (previewPage) {
      if (previewPage === PAGE_AUTH) {
        return <Welcome />;
      }

      if (previewPage === PAGE_CONVERSATION) {
        return <Chat />;
      }

      if (previewPage === PAGE_QUEUE) {
        return <Queue />;
      }

      if (previewPage === PAGE_TERMS_OF_USE) {
        return <TermsOfUse />;
      }
    }
    
    if (showChat) {
      return <Chat />;
    }

    if (showTerms) {
      return <TermsOfUse />;
    }

    return <Welcome />;
  }

  return (
    <React.Fragment>
      <div className='App'>
        {!errorFetchingConfig && ready
          ? (
            <>
              <Header />
              {renderCompanyHeader && <CompanyHeader />}
              {loadBody()}
              <Footer />
            </>
          )
          : ''
        }

        {errorFetchingConfig && ready
          ? (
            <>
              <Header />
              {renderCompanyHeader && <CompanyHeader />}
              <FetchingErrorMessage />
              <Footer />
            </>
          )
          : ''
        }

        <Loading />
        <Dialog />
      </div>
    </React.Fragment>
  );
};

export default App;
