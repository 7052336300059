
const validateEmail = email => {
  const emailPattern = /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+/;
  const re = new RegExp(emailPattern, 'g');

  return email.match(re);
};

const validateCpf = (normalizedValue) => {
  const cpfPattern = /((\d{1,3})(\s+|\.|-|\\|\/)?(\d{3})(\s+|\.|-|\\|\/)?(\d{3})(\s+|\.|-|\\|\/)?(\d{2}))/;
  const re = new RegExp(cpfPattern, 'g');

  if (!normalizedValue.match(re)) {
    return false;
  }

  if (normalizedValue.length !== 11) {
    return false;
  }

  let sum = 0;
  if (normalizedValue === '00000000000' ||
    normalizedValue === '11111111111' ||
    normalizedValue === '22222222222' ||
    normalizedValue === '33333333333' ||
    normalizedValue === '44444444444' ||
    normalizedValue === '55555555555' ||
    normalizedValue === '66666666666' ||
    normalizedValue === '77777777777' ||
    normalizedValue === '88888888888' ||
    normalizedValue === '99999999999') { return false; }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(normalizedValue.substring(i - 1, i), 10) * (11 - i);
  }
  let leftover = (sum * 10) % 11;

  if ((leftover === 10) || (leftover === 11)) {
    leftover = 0;
  }
  if (leftover !== parseInt(normalizedValue.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(normalizedValue.substring(i - 1, i), 10) * (12 - i);
  }
  leftover = (sum * 10) % 11;

  if ((leftover === 10) || (leftover === 11)) {
    leftover = 0;
  }
  if (leftover !== parseInt(normalizedValue.substring(10, 11), 10)) {
    return false;
  }
  return true;
};

const validateCnpj = (normalizedValue) => {
  const cnpjPattern = /((\d{1,2})(\s+|\.|-|\\)?(\d{3})(\s+|\.|-|\\)?(\d{3})(\s+|\.|-|\\|\/)?(\d{4})(\s+|\.|-|\\|\/)?(\d{2}))/;
  const re = new RegExp(cnpjPattern, 'g');

  if (!normalizedValue.match(re)) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (normalizedValue === '00000000000000' ||
    normalizedValue === '11111111111111' ||
    normalizedValue === '22222222222222' ||
    normalizedValue === '33333333333333' ||
    normalizedValue === '44444444444444' ||
    normalizedValue === '55555555555555' ||
    normalizedValue === '66666666666666' ||
    normalizedValue === '77777777777777' ||
    normalizedValue === '88888888888888' ||
    normalizedValue === '99999999999999') { return false; }

  // Valida DVs
  let tamanho = normalizedValue.length - 2;
  let numeros = normalizedValue.substring(0, tamanho);
  const digitos = normalizedValue.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(0)) {
    return false;
  }

  tamanho += 1;
  numeros = normalizedValue.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado.toString() !== digitos.charAt(1)) {
    return false;
  }
  return true;
};

const validatePhone = phone => {
  const phonePattern = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

  const re = new RegExp(phonePattern, 'g');

  return phone.match(re);
};


export default fields => {
  if (!fields) {
    return true;
  }

  return fields.map(field => {
    if (field.required && !field.value) {
      return { ...field, value: field.value || '', error: true };
    }

    if (field.value) {
      let sanitizedValue = field.value.trim();
      switch (field.type) {
        case 'email':
          if (!validateEmail(sanitizedValue)) {
            return { ...field, value: sanitizedValue, error: true };
          }
          break;
        case 'cpf':
          if (!validateCpf(sanitizedValue.replace(/\D/g, '').padStart(11, '0'))) {
            return { ...field, value: sanitizedValue, error: true };
          }
          break;
        case 'cnpj':
          if (!validateCnpj(sanitizedValue.replace(/\D/g, '').padStart(14, '0'))) {
            return { ...field, value: sanitizedValue, error: true };
          }
          break;
        case 'phone':
          if (!validatePhone(sanitizedValue)) {
            return { ...field, value: sanitizedValue, error: true };
          }
          break;
        default:
          break;
      }
    }

    return { ...field, value: field.value.trim() || '', error: false };
  });
};
