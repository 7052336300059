import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const CardMessage = props => {

  const findAndReplaceUrl = (plainText) => {
    const re = /(https?:\/\/[^\s<>{}|^~[\]\\"'`]+)/g;
    return plainText.replace(re, (url) => `<a href="${url}" target="_blank">${url}</a>`);
  };

  return (
    <div className={styles.cardMessageContainer} dangerouslySetInnerHTML={{__html: findAndReplaceUrl(props.message)}} />
  );
};

CardMessage.propTypes = {
  url: PropTypes.array,
  message: PropTypes.string.isRequired,
};

export default React.memo(CardMessage);
