import {
  SHOW_CHAT,
  SHOW_TERMS,
  MESSAGE_RECEIVED,
  SUBMIT_MESSAGE,
  HANDLE_MESSAGE_INPUT_CHANGE,
  SHOW_EMOJI_PICKER,
  ATTACH_FILE,
  SHOW_SYSTEM_TYPING,
  RESTORE_MESSAGES_HISTORY,
  START_CHAT,
  CANCEL_CHAT,
  DISCONNECT_CHAT,
  RECONNECT_CHAT,
  SET_GLOBAL_TRIGGERS,
  SET_SHOW_TYPING,
  FINISH_CHAT,
  CLEAR_MESSAGES,
} from '../actions/actionTypes';
import { FLOW_CANCELLED_MESSAGE, FLOW_FINISHED_MESSAGE } from '../../constants';

const INITIAL_STATE = {
  showChat: false,
  showTerms: false,
  messageToSend: '',
  messages: [],
  alternatives: [],
  messageType: '',
  systemIsTyping: false,
  showEmojiPicker: false,
  sendingFile: false,
  oldToNew: true,
  conversationHistory: {
    hasMoreMessages: false,
    earliestMessage: new Date()
  },
  globalTriggers: [],
  showTyping: false
};

export const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_CHAT:
      return {
        ...state,
        showChat: action.showChat,
      };
    case SHOW_TERMS:
      return {
        ...state,
        showTerms: action.showTerms,
      };
    case MESSAGE_RECEIVED:
      return {
        ...state,
        oldToNew: true,
        alternatives: action.alternatives,
        messageType: action.messageType,
        messages: [...state.messages, { system: true, content: action.messageReceived, type: action.messageType, sentAt: action.sentAt }],
      };
    case SUBMIT_MESSAGE:
      return {
        ...state,
        oldToNew: true,
        messages: [...state.messages, { system: false, content: action.messageSent, type: action.messageType, sentAt: action.sentAt }],
        alternatives: action.alternatives,
        messageToSend: action.resetMessageToSend,
        systemIsTyping: action.systemIsTyping,
      };
    case HANDLE_MESSAGE_INPUT_CHANGE:
      return {
        ...state,
        messageToSend: action.messageToSend,
      };
    case SHOW_EMOJI_PICKER:
      return {
        ...state,
        showEmojiPicker: action.value,
      };
    case ATTACH_FILE:
      return {
        ...state,
        messages: [...state.messages, { system: false, content: action.messageSent, type: action.messageType, sentAt: action.sentAt }],
        alternatives: action.alternatives,
        messageToSend: action.resetMessageToSend,
        systemIsTyping: action.systemIsTyping,
        sendingFile: action.sendingFile,
      };
    case SHOW_SYSTEM_TYPING:
      return {
        ...state,
        systemIsTyping: action.systemIsTyping,
      };
    case RESTORE_MESSAGES_HISTORY:
      if (state.messages.length === 0) {
        return {
          ...state,
          oldToNew: false,
          alternatives: action.alternatives,
          messageType: action.messageType,
          messages: [ ...action.history, ...state.messages ],
          conversationHistory: { ...action.conversationHistory }
        };
      }
      return {
        ...state,
        oldToNew: false,
        messages: [ ...action.history, ...state.messages ],
        conversationHistory: { ...action.conversationHistory }
      };
    case START_CHAT:
      return {
        ...state,
        oldToNew: true,
        messages: [...state.messages, { system: false, content: action.messageSent, type: action.messageType, sentAt: action.sentAt }],
        messageType: action.messageType,
        systemIsTyping: action.systemIsTyping,
      };
    case CANCEL_CHAT:
    case CLEAR_MESSAGES:
      return {
        ...state,
        oldToNew: true,
        messages: [],
        systemIsTyping: false,
      };
    case DISCONNECT_CHAT:
      return {
        ...state,
        oldToNew: true,
        messageType: action.messageType,
        messages: [...state.messages, { content: FLOW_CANCELLED_MESSAGE, type: action.messageType }],
      };
    case FINISH_CHAT:
      return {
        ...state,
        oldToNew: true,
        messageType: action.messageType,
        messages: [...state.messages, { content: FLOW_FINISHED_MESSAGE, type: action.messageType }],
        systemIsTyping: false,
      };
    case RECONNECT_CHAT:
      return {
        ...state,
        oldToNew: true,
        messageType: action.messageType,
        messages: [ ...state.messages, { type: action.messageType }],
      };
    case SET_GLOBAL_TRIGGERS:
      return {
        ...state,
        globalTriggers: action.globalTriggers,
      };
    case SET_SHOW_TYPING:
      return {
        ...state,
        showTyping: action.showTyping,
      };
    default:
      return state;
  }
};
