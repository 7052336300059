import { FORM_METADATA_CACHE_NAME } from "../constants";

const convertFieldsToJson = (formFields) => {

  function sanitizeNumber(value) {
    return value.replace(/[^\d]/g, '');
  };

  return {
    name  : formFields.find((value) => value.name === "name").value,
    phone : sanitizeNumber(formFields.find((value) => value.name === "phone").value)
  }
};

export const getFormMetadataFromCached = () => {
  try {
    return JSON.parse(localStorage.getItem(FORM_METADATA_CACHE_NAME)).formMetadata;  
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const getFormFieldsFormCached = () => {
  try {
    return JSON.parse(localStorage.getItem(FORM_METADATA_CACHE_NAME));  
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const setFormMetadataInCache = (formFields, welcomeMessage) => {
  try {
    let formMetadata = convertFieldsToJson(formFields);
    let metadata = {
      formFields,
      welcomeMessage,
      formMetadata
    };
    localStorage.setItem(FORM_METADATA_CACHE_NAME, JSON.stringify(metadata));
  } catch (error) {
    console.error(error);
    return false;
  }
}

export const clearCacheFormMetadata = () => {
  localStorage.removeItem(FORM_METADATA_CACHE_NAME)
}