import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import CardMessage from './Message';
import CardImage from './Image';
import CarouselImage from './Carousel';
import { MESSAGE_TYPE_FILES } from '../../../../constants';

import styles from './styles.module.css';
import stylesChat from '../../styles.module.css';

const Card = props => {
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);
  const imgSystem = useSelector(state => state.configState.styles.img192);

  function getCardBaseColor() {
    return props.isFromSystem ? '#b3b3b3' : baseColor;
  }

  function getCardForegroundColor() {
    return props.isFromSystem ? '#ffffff' : foregroundColor;
  }

  function renderCard() {
    if (props.message.content) {
      if (props.message.type === MESSAGE_TYPE_FILES) {
        if (props.message.content.length > 1) {
          return (
            <CarouselImage
              images={props.message.content}
              baseColor={getCardBaseColor()}
              foregroundColor={getCardForegroundColor()}
              animationClasses={getAnimation()}
            />
          );
        } else {
          return (
            <CardImage
              images={props.message.content}
              baseColor={getCardBaseColor()}
              foregroundColor={getCardForegroundColor()}
              animationClasses={getAnimation()}
            />
          );
        }
      } else {
        return (
          <CardMessage
            message={props.message.content}
            baseColor={getCardBaseColor()}
            foregroundColor={getCardForegroundColor()}
            animationClasses={getAnimation()}
          />
        );
      }
    }
  }

  function getAnimation() {
    if (props.message.history) return '';

    if (props.isFromSystem) {
      return 'animated slideInLeft';
    }
    return 'animated slideInRight';
  }

  function applyStyle() {
    if (props.isFromSystem) {
      return `${getAnimation()} ${stylesChat.card} ${stylesChat.cardMessageRobot}`;
    } else {
      return `${getAnimation()} ${stylesChat.card} ${stylesChat.cardMessageUser}`;
    }
  }

  function renderLogoIfCardIsFromSystem() {
    if (props.isFromSystem && props.message.content) {
      return <img className={styles.companyLogoChat} src={imgSystem} alt="Company logo" />;
    }
  }

  const cardStyle = () => {
    if (props.isFromSystem) {
      return { backgroundColor: baseColor, color: foregroundColor };
    }
    return {};
  };

  return (
    <div className={applyStyle()}>
      {renderLogoIfCardIsFromSystem()}
      <div className={styles.messageContainer}>
        <div className={stylesChat.cardMessageBalloon} style={cardStyle()}>
          { renderCard() }
        </div>
        <div className={styles.messageSentAt}>
          { props.message.sentAt }
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  message: PropTypes.object.isRequired,
  isFromSystem: PropTypes.bool.isRequired,
};

export default React.memo(Card);
