import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

const CompanyLogo = () => {
  const name = useSelector(state => state.configState.styles.name);
  const logo = useSelector(state => state.configState.styles.img512);

  return (
    <div data-testid="companyLogo" className={`animated fadeIn slower ${styles.companyLogo}`}>
      <img src={logo} alt={name} />
    </div>
  );
};

export default React.memo(CompanyLogo);
