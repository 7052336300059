import { isMobile } from 'react-device-detect';
import chatConfig from './chatConfig';
import { swRegistration } from '../serviceWorker';

export const sendNotification = (text = '') => {
  const dispatchNotification = (notificationMessage, notificationConfig) => {
    if (!document.hidden)
      return;

    if (isMobile) {
      swRegistration.showNotification(notificationMessage, notificationConfig);
    } else {
      new Notification(notificationMessage, notificationConfig);
    }
  };

  const notificationConfig = { body: text, icon: chatConfig.img192 };
  let notificationMessage = 'Nova mensagem';

  if (chatConfig.name) {
    notificationMessage = `Nova mensagem de ${chatConfig.name}`;
  }

  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      dispatchNotification(notificationMessage, notificationConfig);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        if (permission === 'granted') {
          dispatchNotification(notificationMessage, notificationConfig);
        }
      });
    }
  }
};

export default {
  sendNotification,
};
