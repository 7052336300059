import { HANDLER_VISIBLE_CHAT, HANDLER_LOADING, SHOW_DIALOG, HIDE_DIALOG } from './actionTypes';

const handlerChat = value => {
  return {
    type: HANDLER_VISIBLE_CHAT,
    showApp: value,
  };
};

const handlerLoading = isLoading => {
  return {
    type: HANDLER_LOADING,
    isLoading
  };
};

const showDialog = dialogData => {
  return {
    type: SHOW_DIALOG,
    dialogData,
  };
};

const hideDialog = () => {
  return {
    type: HIDE_DIALOG,
    dialogData: {
      show: false
    }
  };
};

export {
  handlerChat,
  handlerLoading,
  showDialog,
  hideDialog
};
