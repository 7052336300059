import { MESSAGE_TYPE_FILES, MESSAGE_TYPE_ATTACHMENT } from '../constants';

export const normalizeMessage = message => {
  const newMessage = Object.assign({}, message);

  if (message.type === MESSAGE_TYPE_ATTACHMENT) {
    newMessage.type = MESSAGE_TYPE_FILES;

    if (!Array.isArray(message.content)) {
      newMessage.content = [{ url: message.content }];
    }
  } else if (message.type !== MESSAGE_TYPE_FILES) {
    if (Array.isArray(message.content) && message.content.length === 1) {
      newMessage.content = message.content[0].replace(/\r\n|\r|\n/g, '<br />');
    } else if (!Array.isArray(message.content)) {
      newMessage.content = message.content ? message.content.replace(/\r\n|\r|\n/g, '<br />') : "";
    }
  }

  return newMessage;
};

export const normalizeMessageTimestamp = (date) => `${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
