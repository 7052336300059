import React from 'react';
import CompanyLogo from './Logo';
import CompanyInfo from './Info';

import styles from './styles.module.css';

const Company = () => {
  return (
    <div data-testid="companySession" className={styles.company}>
      <CompanyLogo />
      <CompanyInfo />
    </div>
  );
};

export default React.memo(Company);
