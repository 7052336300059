const queryParams = new URLSearchParams(window.location.search);
const bufferDecode = queryParams.get("config") ? Buffer.from(queryParams.get("config"), "base64") : null;
const jsonDecoded = bufferDecode ? JSON.parse(bufferDecode.toString()) : {};

export const getBaseUrl = () => {
  const regexGetUrl = /^.+?[^/:](?=[?/]|$)/;

  return regexGetUrl.exec(jsonDecoded.url)[0];
};

export const getChannelId = () => {
  return jsonDecoded.channelId;
};