import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

function Loading() {
  const isLoading = useSelector(state => state.appState.isLoading);
  const isWidget = useSelector(state => state.configState.isWidget);
  const isDraw = useSelector(state => state.configState.isDraw);

  let borderRadiusClass = '';
  if (isWidget || isDraw) {
    borderRadiusClass = `${styles.headerBorderRadius}`;
  }

  return (
    <div className={`${styles.container} ${!isLoading && styles.hidden} ${borderRadiusClass}`}>
      <div className={styles.ring}></div>
      <h1 className={styles.loadingTitle}>Conectando</h1>
    </div>
  );
}

export default Loading;
