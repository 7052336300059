import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

const CompanyInfo = () => {
  const name = useSelector(state => state.configState.styles.name);
  const description = useSelector(state => state.configState.styles.description);

  return (
    <div data-testid="companyInfo" className="animated fadeIn slower">
      <h3 className={styles.companyInfoName}> { name } </h3>
      <h5 className={styles.companyInfoDescription}> { description } </h5>
    </div>
  );
};

export default React.memo(CompanyInfo);
