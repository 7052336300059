import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { disconnectChatter } from '../../services/chatter';
import Conversation from './Conversation';
import UserInput from './UserInput';
import {
  messageReceived,
  sendMessage,
  showSystemTyping,
  clearMessages,
} from '../../store/actions/chatActions';

import styles from './styles.module.css';

function Chat() {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.chatState.messages);
  const isDraw = useSelector(state => state.configState.isDraw);

  useEffect(() => {
    if (isDraw) {
      dispatch(sendMessage('Customizações'));

      dispatch(messageReceived(Object.assign({}, {
        type: 'text',
        content: 'Olá, sobre customizações, vamos lá então!',
        sentAt: Date.now()
      })));

      dispatch(messageReceived(Object.assign({}, {
        type: 'text',
        content: 'Para customizar o Newchat preencha o formulário ao lado e veja as alterações em tempo real!',
        sentAt: Date.now()
      })));

      dispatch(showSystemTyping(false));
    }

    return () => {
      disconnectChatter();

      if (isDraw) {
        dispatch(clearMessages());
      }
    };
  }, [dispatch, isDraw]);

  return (
    <div className={styles.chat}>
      <Conversation />
      {messages && messages.length > 0 ? <UserInput /> : null}
    </div>
  );
}

export default Chat;
