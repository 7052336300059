import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Button = props => (
  <div>
    <button
      data-testid="headerButton"
      className={styles.headerButton}
      onClick={props.onClick}
      style={{color: props.color}}
    >
      {props.icon}
    </button>
  </div>
);

Button.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default React.memo(Button);
