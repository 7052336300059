import store from '../store';
import {
  setPreviewPage,
  setQueueMessage,
  setTermsOfUseId,
  setTermsOfUseType,
  setTermsOfUseTitle,
  setTermsOfUse,
  setBaseColor,
  setTitle,
  setName,
  setDescription,
  setImg192,
  setImg512,
  setFooterText,
  setFooterUrl,
  setChatConfigs,
  chatConfig,
  setHasTermsOfUse,
} from './chatConfig';
import {
  updatePreviewPage,
  updateQueueMessage,
  updateHasTermsOfUse,
  updateTermsOfUseId,
  updateTermsOfUseType,
  updateTermsOfUseTitle,
  updateTermsOfUse,
  updateBaseColor,
  updateTitle,
  updateName,
  updateDescription,
  updateImg192,
  updateImg512,
  updateFooterText,
  updateFooterUrl,
  updateConfigs,
  updateShowFormDraw,
  updateFormFields,
  updateErrorFetchingConfig,
} from '../store/actions/configActions';

export const updateChatConfigState = (configs) => {
  setChatConfigs(configs || {});
  store.dispatch(updateConfigs(chatConfig));
};

export const fetchingConfigErrorHandler = () => {
  store.dispatch(updateErrorFetchingConfig(true));
};

export const init = () => {
  const receiveMessage = event => {
    if (event && event.data) {
      if (event.data.hasOwnProperty('baseColor')) {
        setBaseColor(event.data.baseColor);
        store.dispatch(updateBaseColor(chatConfig.styles.baseColor));
      }

      if (event.data.hasOwnProperty('title')) {
        setTitle(event.data.title);
        store.dispatch(updateTitle(chatConfig.styles.title));
      }

      if (event.data.hasOwnProperty('name')) {
        setName(event.data.name);
        store.dispatch(updateName(chatConfig.styles.name));
      }

      if (event.data.hasOwnProperty('description')) {
        setDescription(event.data.description);
        store.dispatch(updateDescription(chatConfig.styles.description));
      }

      if (event.data.hasOwnProperty('img192')) {
        setImg192(event.data.img192);
        store.dispatch(updateImg192(chatConfig.styles.img192));
      }

      if (event.data.hasOwnProperty('img512')) {
        setImg512(event.data.img512);
        store.dispatch(updateImg512(chatConfig.styles.img512));
      }

      if (event.data.hasOwnProperty('footerText')) {
        setFooterText(event.data.footerText);
        store.dispatch(updateFooterText(chatConfig.styles.footerText));
      }

      if (event.data.hasOwnProperty('footerUrl')) {
        setFooterUrl(event.data.footerUrl);
        store.dispatch(updateFooterUrl(chatConfig.styles.footerUrl));
      }
      
      if (event.data.hasOwnProperty('showForm')) {
        store.dispatch(updateShowFormDraw(event.data.showForm));
      }

      if (event.data.hasOwnProperty('formFields')) {
        store.dispatch(updateFormFields(event.data.formFields));
      }

      if (event.data.hasOwnProperty('configs')) {
        updateChatConfigState(event.data.configs);
      }

      if (event.data.hasOwnProperty('previewPage')) {
        setPreviewPage(event.data.previewPage);
        store.dispatch(updatePreviewPage(event.data.previewPage));
      }

      if (event.data.hasOwnProperty('hasTermsOfUse')) {
        setHasTermsOfUse(event.data.hasTermsOfUse);
        store.dispatch(updateHasTermsOfUse(event.data.hasTermsOfUse));
      }

      if (event.data.hasOwnProperty('termsOfUse')) {
        setTermsOfUse(event.data.termsOfUse);
        store.dispatch(updateTermsOfUse(event.data.termsOfUse));
      }

      if (event.data.hasOwnProperty('termsOfUseId')) {
        setTermsOfUseId(event.data.termsOfUseId);
        store.dispatch(updateTermsOfUseId(event.data.termsOfUseId));
      }

      if (event.data.hasOwnProperty('termsOfUseTitle')) {
        setTermsOfUseTitle(event.data.termsOfUseTitle);
        store.dispatch(updateTermsOfUseTitle(event.data.termsOfUseTitle));
      }

      if (event.data.hasOwnProperty('termsOfUseType')) {
        setTermsOfUseType(event.data.termsOfUseType);
        store.dispatch(updateTermsOfUseType(event.data.termsOfUseType));
      }

      if (event.data.hasOwnProperty('queueMessage')) {
        setQueueMessage(event.data.queueMessage);
        store.dispatch(updateQueueMessage(event.data.queueMessage));
      }
    }
  };

  window.addEventListener('message', receiveMessage, false);
};
