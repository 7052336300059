import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { connectChatter } from '../../../../services/chatter';
import { updateFormFields, updateFormSubmitted } from '../../../../store/actions/configActions';
import validateForm from '../../../../services/formValidator';

import styles from './styles.module.css';

const StartButton = () => {
  const dispatch = useDispatch();
  const isDraw = useSelector(state => state.configState.isDraw);
  const form = useSelector(state => state.configState.form);
  const baseColor = useSelector(state => state.configState.styles.baseColor);

  const handleStartButton = async () => {
    if (isDraw) return;

    if (form && form.fields) {
      const formFields = validateForm(form.fields);

      dispatch(updateFormFields(formFields));

      const firstError = formFields.find(f => f.error);
      if (firstError) {
        document.querySelectorAll(`input[name='${firstError.name}']`)[0].focus();
      } else {
        dispatch(updateFormSubmitted(true));

        if (form.showFormMessage) {
          let formMessage = 'Olá seguem os dados solicitados para o atendimento:';
          form.fields.forEach(field => {
            formMessage += field.value ? `<br>${field.label}: ${field.value}` : '';
          });

          await connectChatter(form.fields, formMessage);
        } else {
          await connectChatter(form.fields);
        }
      }
    } else {
        await connectChatter();
    }
  };

  return (
    <div className={styles.startButton}>
      <button onClick={handleStartButton} style={{color: baseColor}}>
        Iniciar conversa
      </button>
    </div>
  );
};

export default StartButton;
