import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideDialog } from '../../store/actions/appActions';

import styles from './styles.module.css';

const Dialog = () => {
  const dispatch = useDispatch();
  const title = useSelector(state => state.appState.dialogData.title || '');
  const content = useSelector(state => state.appState.dialogData.content || '');
  const buttons = useSelector(state => state.appState.dialogData.buttons || []);
  const show = useSelector(state => state.appState.dialogData.show || false);
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);

  let classes = `${styles.dialogBackdrop}`;

  if (!show) {
    classes = `${classes} ${styles.dialogHidden}`;
  }

  return (
    <div data-testid="dialogComponent" className={classes} >
      <div className={styles.dialog}>
        <div className={styles.dialogHeader}>
          <h2 className={styles.dialogHeaderTitle}>{title}</h2>
        </div>

        <div className={styles.dialogContent}>
          <p>{content}</p>
        </div>

        <div className={styles.dialogFotter}>
          {buttons.map((button, index) => {
            let handlerButtonClick = () => {};

            const buttonClassName = button.type !== 'primary' ? styles.buttonBasic : '';
            const buttonStyle = button.type === 'primary' ? {backgroundColor: baseColor, color: foregroundColor} : {};

            handlerButtonClick = () => {
              if (button.onClick) {
                button.onClick();
              }
              dispatch(hideDialog());
            };

            return (
              <button
                key={index}
                onClick={handlerButtonClick}
                className={buttonClassName}
                style={buttonStyle}
              >
                {button.label}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
