import {
  SHOW_CHAT,
  SHOW_TERMS,
  MESSAGE_RECEIVED,
  SUBMIT_MESSAGE,
  HANDLE_MESSAGE_INPUT_CHANGE,
  SHOW_EMOJI_PICKER,
  ATTACH_FILE,
  SHOW_SYSTEM_TYPING,
  RESTORE_MESSAGES_HISTORY,
  START_CHAT,
  CANCEL_CHAT,
  DISCONNECT_CHAT,
  RECONNECT_CHAT,
  SHOW_SENDING_FILE,
  SET_GLOBAL_TRIGGERS,
  SET_SHOW_TYPING,
  FINISH_CHAT,
  CLEAR_MESSAGES,
 } from './actionTypes';
import {
  MESSAGE_TYPE_TEXT,
  MESSAGE_TYPE_ALTERNATIVE_QUESTION,
  MESSAGE_TYPE_FILES,
  MESSAGE_TYPE_START,
  MESSAGE_TYPE_CANCEL,
  MESSAGE_TYPE_FINALIZATION,
  MESSAGE_TYPE_RECONNECT,
  MESSAGE_TYPE_FINISHED,
  FLOW_FINISHED_MESSAGE,
  FLOW_CANCELLED_MESSAGE,
} from '../../constants';
import { sendMessageChatter, sendAttachmentChatter } from '../../services/chatter';
import { getConversationStartHash, getConversationFinalizationHash } from '../../services/chatConfig';
import { normalizeMessage, normalizeMessageTimestamp } from '../../services/normalizer';

const messageReceived = ({ type, alternatives, content, sentAt }) => {
  let newState = {
    type: MESSAGE_RECEIVED,
    messageReceived: content,
    alternatives: [],
    messageType: type,
    sentAt: normalizeMessageTimestamp(new Date(sentAt))
  };

  if (type === MESSAGE_TYPE_ALTERNATIVE_QUESTION) {
    newState.alternatives = alternatives;
  }

  return newState;
};

const sendMessage = (message) => {
  sendMessageChatter(message);

  return {
    type: SUBMIT_MESSAGE,
    messageSent: message,
    sentAt: normalizeMessageTimestamp(new Date()),
    messageType: MESSAGE_TYPE_TEXT,
    resetMessageToSend: '',
    alternatives: [],
    systemIsTyping: true,
  };
};

const startChat = (form) => {
  sendMessageChatter(getConversationStartHash(form));

  return {
    type: START_CHAT,
    messageSent: getConversationStartHash(),
    sentAt: normalizeMessageTimestamp(new Date()),
    messageType: MESSAGE_TYPE_START,
    systemIsTyping: true,
  };
};

const finalizeChat = () => {
  sendMessageChatter(getConversationFinalizationHash());

  return {
    type: CANCEL_CHAT,
    messageSent: getConversationFinalizationHash(),
    sentAt: normalizeMessageTimestamp(new Date()),
    messageType: MESSAGE_TYPE_CANCEL,
  };
};

const disconnectChat = () => {
  return {
    type: DISCONNECT_CHAT,
    messageType: MESSAGE_TYPE_FINALIZATION,
  };
};

const finishChat = () => {
  return {
    type: FINISH_CHAT,
    messageType: MESSAGE_TYPE_FINISHED,
  };
};

const reconnectChat = () => {
  return {
    type: RECONNECT_CHAT,
    messageType: MESSAGE_TYPE_RECONNECT,
  };
};

const handleMessageInputChange = e => {
  return {
    type: HANDLE_MESSAGE_INPUT_CHANGE,
    messageToSend: e.target.value,
  };
};

const toggleEmojiPickerVisibility = (value) => {
  return {
    type: SHOW_EMOJI_PICKER,
    value,
  };
};

const sendAttachment = (e) => {
  return async (dispatch) => {
    const element = e.target || e.srcElement;
    const file = element.files[0];

    try {
      const sentFile = await sendAttachmentChatter(file);
      let messageSent = [{ type: file.type, url: URL.createObjectURL(file) }];

      if (sentFile && sentFile.attachment) {
        messageSent = [{ type: sentFile.attachment.contentType, ...sentFile.attachment }];
      }

      dispatch({
        type: ATTACH_FILE,
        messageSent,
        sentAt: normalizeMessageTimestamp(new Date()),
        messageType: MESSAGE_TYPE_FILES,
        resetMessageToSend: '',
        alternatives: [],
        systemIsTyping: true,
        sendingFile: false,
      });
    } catch(err) {
      console.error(err);
    }

    element.value = "";
  };
};

const showSystemTyping = (isTyping) => {
  return {
    type: SHOW_SYSTEM_TYPING,
    systemIsTyping: isTyping,
  };
};

const restoreMessagesHistory = rawHistory => {
  const history = [];
  const conversationHistory = { hasMoreMessages: false };
  let alternatives = [];
  let messageType = 'text';

  if (rawHistory) {
    if (rawHistory.length === 10) {
      conversationHistory.hasMoreMessages = true;
      conversationHistory.earliestMessage = rawHistory[0].sentAt;
    }

    rawHistory.forEach(element => {
      const system = !element.sent;
      const historyMessage = {
        system: !element.sent,
        content: element.body,
        type: 'text',
        sentAt: normalizeMessageTimestamp(new Date(element.sentAt)),
        history: true,
      };

      if (system) {
        try {
          const bodyParsed = JSON.parse(element.body);
          bodyParsed.forEach(message => {
            if (message.type === MESSAGE_TYPE_FINISHED) {
              message.content = FLOW_FINISHED_MESSAGE;
            }
            if (message.type === MESSAGE_TYPE_CANCEL) {
              message.content = FLOW_CANCELLED_MESSAGE;
            }

            const messageNormalized = normalizeMessage(message);

            history.push(Object.assign({}, historyMessage, messageNormalized));

            alternatives = messageNormalized.alternatives;
            messageType = messageNormalized.type;
          });
        } catch {
          console.error('[ChatActions] - Error trying to parse history message');
        }
      } else {
        alternatives = [];
        history.push(historyMessage);
      }
    });

    return {
      type: RESTORE_MESSAGES_HISTORY,
      alternatives,
      messageType,
      history,
      conversationHistory
    };
  }
};

const showSendingFile = (sendingFile) => {
  return {
    type: SHOW_SENDING_FILE,
    sendingFile: sendingFile,
  };
};

const setGlobalTriggers = globalTriggers => {
  return {
    type: SET_GLOBAL_TRIGGERS,
    globalTriggers
  };
};

const setShowTyping = showTyping => {
  return {
    type: SET_SHOW_TYPING,
    showTyping
  };
};

const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES
  };
};

const showChat = (value) => {
  return {
    type: SHOW_CHAT,
    showChat: value
  };
};

const showTerms = (value) => {
  return {
    type: SHOW_TERMS,
    showTerms: value
  };
};

export {
  showChat,
  showTerms,
  messageReceived,
  sendMessage,
  handleMessageInputChange,
  toggleEmojiPickerVisibility,
  sendAttachment,
  showSystemTyping,
  restoreMessagesHistory,
  startChat,
  finalizeChat,
  finishChat,
  disconnectChat,
  reconnectChat,
  showSendingFile,
  setGlobalTriggers,
  setShowTyping,
  clearMessages,
};
