import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import Card from './Card';
import Typing from './Typing';
import { showChat, finalizeChat, showTerms } from '../../../store/actions/chatActions';
import { resetForm } from '../../../store/actions/configActions';
import { disconnectChatter } from '../../../services/chatter';
import Queued from './Queued';

import {
  MESSAGE_TYPE_QUEUE,
  MESSAGE_TYPE_QUEUE_FINISHED,
  MESSAGE_TYPE_START,
  MESSAGE_TYPE_CANCEL,
  MESSAGE_TYPE_FINALIZATION,
  MESSAGE_TYPE_RECONNECT,
  CONVERSATION_HISTORY_TRIGGER_POSITION,
  CONVERSATION_HISTORY_THROTTLE_DELAY,
  MESSAGE_TYPE_FINISHED,
} from '../../../constants';

import * as styles from './styles.module.css';

const Conversation = props => {
  const dispatch = useDispatch();
  const oldToNew = useSelector(state => state.chatState.oldToNew);
  const messages = useSelector(state => state.chatState.messages);
  const showTyping = useSelector(state => state.configState.showTyping);
  const systemIsTyping = useSelector(state => state.chatState.systemIsTyping);
  const conversationHistory = useSelector(state => state.chatState.conversationHistory);
  const messageType = useSelector(state => state.chatState.messageType);
  const isWidget = useSelector(state => state.configState.isWidget);

  const setScrollHeight = useState(0)[1];

  const setTop = useCallback((top) => {
    const body = document.getElementsByClassName(styles.conversation)[0];
    body.scrollTo({ top, left: 0, behavior: 'smooth' });
  }, []);

  const onScroll = (function () {
    let delayTime = CONVERSATION_HISTORY_THROTTLE_DELAY;
    let lastExecution = new Date((new Date()).getTime() - delayTime);

    let innerOnScroll = function() {
      // loadConversationHistory(time);
    };

    return function(time) {
      if ((lastExecution.getTime() + delayTime) <= (new Date()).getTime()) {
        lastExecution = new Date();
        return innerOnScroll.apply(this, [time]);
      }
    };
  })();

  const scrollTop = useCallback(() => {
    setScrollHeight((height) => {
      const body = document.getElementsByClassName(styles.conversation)[0];
      const newHeight = body.scrollHeight - height;
      body.scrollTop = newHeight;
      return body.scrollHeight;
    });
  }, [setScrollHeight]);

  const scrollDown = useCallback(() => {
    const body = document.getElementsByClassName(styles.conversation)[0];
    setScrollHeight(body.scrollHeight);
    setTop(body.scrollHeight);
  }, [setScrollHeight, setTop]);

  const scroll = useCallback(() => oldToNew ? scrollDown() : scrollTop(), [oldToNew, scrollDown, scrollTop]);

  useEffect(() => {
    const body = document.getElementsByClassName(styles.conversation)[0];
    const cb = () => {
      if (body.scrollTop <= CONVERSATION_HISTORY_TRIGGER_POSITION) {
        onScroll(conversationHistory.earliestMessage);
      }
    };
    if (conversationHistory.hasMoreMessages) {
      body.addEventListener('scroll', cb);
    }
    return () => {
      body.removeEventListener('scroll', cb);
    };
  }, [conversationHistory, oldToNew, onScroll]);

  useEffect(() => {
    scroll();
  }, [messages, scroll]);

  const hideWidgetFrame = () => {
    window.parent.postMessage({ hideFrame: true }, '*');
  };

  const endChat = () => {
    dispatch(finalizeChat());
    dispatch(resetForm());

    if (messageType !== MESSAGE_TYPE_FINALIZATION) {
      disconnectChatter();

      if (isWidget) {
        hideWidgetFrame();
      }

      dispatch(showChat(false));
      dispatch(showTerms(false));
    }
  };

  return (
    <div className={styles.conversation}>
      {props.children}
      {messages.map((m, index) => {
        if (m.type !== MESSAGE_TYPE_RECONNECT) {
          if (m.type === MESSAGE_TYPE_FINALIZATION || m.type === MESSAGE_TYPE_FINISHED) {
            endChat();
          } else if (m.type !== MESSAGE_TYPE_START && m.type !== MESSAGE_TYPE_CANCEL && m.type !== MESSAGE_TYPE_QUEUE && m.type !== MESSAGE_TYPE_QUEUE_FINISHED) {
            return (
              <Card
                key={index}
                message={m}
                isFromSystem={m.system}
              />
            );
          }
        }        
        return '';
      })}

      { <Queued messages={messages} /> }

      { showTyping && systemIsTyping ? <Typing /> : null }
    </div>
  );
};

Conversation.propTypes = {
  children: PropTypes.element
};

export default Conversation;
