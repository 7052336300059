export const SHOW_CHAT = 'SHOW_CHAT';
export const SHOW_TERMS = 'SHOW_TERMS';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SUBMIT_MESSAGE = 'SUBMIT_MESSAGE';
export const HANDLE_MESSAGE_INPUT_CHANGE = 'HANDLE_MESSAGE_INPUT_CHANGE';
export const SHOW_EMOJI_PICKER = 'SHOW_EMOJI_PICKER';
export const ATTACH_FILE = 'ATTACH_FILE';
export const SHOW_SYSTEM_TYPING = 'SHOW_SYSTEM_TYPING';
export const RESTORE_MESSAGES_HISTORY = 'RESTORE_MESSAGES_HISTORY';
export const UPDATE_FORM_FIELDS = 'UPDATE_FORM_FIELDS';
export const UPDATE_FORM_SUBMITTED = 'UPDATE_FORM_SUBMITTED';
export const RESET_FORM = 'RESET_FORM';
export const START_CHAT = 'START_CHAT';
export const CANCEL_CHAT = 'CANCEL_CHAT';
export const FINISH_CHAT = 'FINISH_CHAT';
export const DISCONNECT_CHAT = 'DISCONNECT_CHAT';
export const RECONNECT_CHAT = 'RECONNECT_CHAT';
export const SHOW_SENDING_FILE = 'SHOW_SENDING_FILE';
export const SET_GLOBAL_TRIGGERS = 'SET_GLOBAL_TRIGGERS';
export const SET_SHOW_TYPING = 'SET_SHOW_TYPING';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const HANDLER_VISIBLE_CHAT = 'HANDLER_VISIBLE_CHAT';
export const HANDLER_LOADING = 'HANDLER_LOADING';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

export const UPDATE_MESSAGEQUEUE = 'UPDATE_MESSAGEQUEUE';
export const UPDATE_PREVIEWPAGE = 'UPDATE_PREVIEWPAGE';
export const UPDATE_BASE_COLOR = 'UPDATE_BASE_COLOR';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_IMG192 = 'UPDATE_IMG192';
export const UPDATE_IMG512 = 'UPDATE_IMG512';
export const UPDATE_FOOTER_TEXT = 'UPDATE_FOOTER_TEXT';
export const UPDATE_FOOTER_URL = 'UPDATE_FOOTER_URL';
export const UPDATE_SHOW_FORM_DRAW = 'UPDATE_SHOW_FORM_DRAW';
export const UPDATE_CONFIGS = 'UPDATE_CONFIGS';
export const UPDATE_ERROR_FETCHING_CONFIG = 'UPDATE_ERROR_FETCHING_CONFIG';
