import React from 'react';
import PropTypes from 'prop-types';
import pdfLogo from '../../../../../assets/icons/pdf-file-icon.png';

import styles from './styles.module.css';

const CarouselImages = props => {
  return (
    <div className={`${props.animationClasses} ${styles.imageCardCarousel}`}>
      {
        props.images.map((image, index) => {
          if (image.type === 'video/mp4') {
            return (
              <video
                className={props.animationClasses ? "animated fadeInUp delay-1s" : ""}
                src={image.url}
                key={index}
                alt={index}
                controls />);
          }

          if (image.type === 'application/pdf') {
            return (
              <a href={image.url} target="blank" key={index}>
                <img
                  title="Clique para baixar o documento"
                  className={props.animationClasses ? "animated fadeInUp delay-1s" : ""}
                  src={pdfLogo}
                  alt={index} />
              </a>);
          }

          if (image.type && image.type.indexOf('image') !== -1) {
            return (
              <img
                className={props.animationClasses ? "animated fadeInUp delay-1s" : ""}
                src={image.url}
                key={index}
                alt={index} />
            );
          }

          return (
            <a href={image.url} target="blank" key={index}>
              {image.url}
            </a>
          );
        })
      }
    </div>
  );
};

CarouselImages.propTypes = {
  images: PropTypes.array,
  animationClasses: PropTypes.string,
};

export default React.memo(CarouselImages);
