import chatConfig from './services/chatConfig';

const fillManifest = () => {
  const dynamicManifest = {
    short_name: chatConfig.name,
    name: chatConfig.description,
    icons: [
      {
        src: chatConfig.img512,
        type: 'image/png',
        sizes: '192x192'
      },
      {
        src: chatConfig.img512,
        type: 'image/png',
        sizes: '512x512'
      }
    ],
    start_url: `${window.location.href}`,
    display: 'standalone',
    theme_color: chatConfig.baseColor,
    background_color: '#ffffff'
  };

  const stringManifest = JSON.stringify(dynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
};

fillManifest();
