import React from 'react';
import { useSelector, useDispatch } from "react-redux";

import { sendMessage } from '../../../../store/actions/chatActions';

import styles from './styles.module.css';
import stylesChat from '../../styles.module.css';

const UserInputButtons = () => {
  const dispatch = useDispatch();
  const alternatives = useSelector(state => state.chatState.alternatives);
  const baseColor = useSelector(state => state.configState.styles.baseColor);

  return (
    <div className={styles.cardButtons}>
      {
        alternatives.map((answer, index) => (
          <div
            className={`animated fadeInUp ${stylesChat.card} ${stylesChat.cardMessageButtons} ${styles.cardButtonsItem}`}
            key={index}
          >
            <div
              className={`${stylesChat.cardMessageBalloon} ${styles.cardButtonsItemButton}`}
              onClick={e => { dispatch(sendMessage(e.target.textContent)); }}
              style={{ borderColor: baseColor, color: baseColor }}
            >
              { answer }
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default React.memo(UserInputButtons);
