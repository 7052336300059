import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnectChatter } from '../../services/chatter';
import Conversation from '../Chat/Conversation';
import {
  messageReceived,
  sendMessage,
  showSystemTyping,
  clearMessages,
} from '../../store/actions/chatActions';

import styles from './styles.module.css';

function Queue() {
  const dispatch = useDispatch();
  const isDraw = useSelector(state => state.configState.isDraw);
  const queueMessage = useSelector(state => state.configState.queueMessage);

  useEffect(() => {
    if (isDraw) {
      dispatch(messageReceived(Object.assign({}, {
        type: 'text',
        content: 'Como está o seu dia?',
        sentAt: Date.now()
      })));

      dispatch(sendMessage('Ótimo!'));

      dispatch(messageReceived(Object.assign({}, {
        type: 'queue_type',
        content: queueMessage,
        sentAt: Date.now()
      })));

      dispatch(showSystemTyping(false));
    }

    return () => {
      disconnectChatter();

      if (isDraw) {
        dispatch(clearMessages());
      }
    };
  }, [dispatch, isDraw, queueMessage]);

  return (
    <div className={ styles.container }>
      <Conversation />
    </div>
  );
}

export default Queue;
