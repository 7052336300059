import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

import styles from './styles.module.css';

import { updateFormFields } from '../../store/actions/configActions';

function Form() {
  const dispatch = useDispatch();
  const formFields = useSelector(state => state.configState.form.fields || []);
  const isDraw = useSelector(state => state.configState.isDraw);

  const handlerOnBlur = (e) => {
    const updatedFormFields = formFields.map(field => {
      if (field.name === e.target.name) {
        field.value = e.target.value;
      }
      return field;
    });
    dispatch(updateFormFields(updatedFormFields));
  };

  const getInputMask = type => {
    switch (type) {
      case 'cpf':
        return '999.999.999-99';
      case 'cnpj':
        return '99.999.999/9999-99';
      case 'phone':
        return '(99) 99999-9999';
      default:
        return '';
    }
  };

  const getInputMaxLength = (type, maxLength) => {
    if (getInputMask(type)) {
      return null;
    }

    return maxLength || 50;
  };

  return (
    <div className={ styles.container }>
      {formFields.map((field, index) => (
        <div key={ index } className={styles.fieldWrapper}>
          <label className={`${styles.fieldLabel} ${field.error && styles.fieldLabelError}`}>
            {field.label}:
          </label>
          <InputMask
            mask={getInputMask(field.type)}
            readOnly={isDraw}
            className={`${styles.fieldInput} ${field.error && styles.fieldInputError}`}
            name={field.name}
            type={field.type}
            maxLength={getInputMaxLength(field.type, field.maxLength)}
            onBlur={handlerOnBlur}
          />
        </div>
      ))}
    </div>
  );
}

export default Form;
