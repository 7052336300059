import React from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

const Footer = () => {
  const isWidget = useSelector(state => state.configState.isWidget);
  const isDraw = useSelector(state => state.configState.isDraw);
  const footerText = useSelector(state => state.configState.styles.footerText);
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);
  const footerUrl = useSelector(state => state.configState.styles.footerUrl);

  const styleFooter = {
    color: foregroundColor,
    backgroundColor: baseColor,
  };

  let footerLabel;
  let footerClasses = `${styles.footer}`;

  if (isWidget || isDraw) {
    footerClasses = `${footerClasses} ${styles.footerBorderRadius}`;
  }

  if (footerUrl) {
    footerLabel = (
      <a
        data-testid="footerContentLink"
        href={footerUrl}
        target="blank"
        style={{color: foregroundColor}}
      >
        { footerText }
      </a>
    );
  } else {
    footerLabel = <span style={{color: foregroundColor }}> { footerText } </span>;
  }

  return (
    <footer data-testid="footer" className={footerClasses} style={styleFooter}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentText}>
          {footerLabel}
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
