import React from 'react';
import { useSelector } from "react-redux";

import styles from './styles.module.css';
import stylesApp from '../../styles.module.css';

const Typing = () => {
  const imgSystem = useSelector(state => state.configState.styles.img192);
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);

  return (
    <div data-testid="typingComponent" className={styles.typing}>
      <img src={imgSystem} alt="Company logo" />
      <div className={stylesApp.cardMessageRobot}>
        <div className={`${styles.ellipsis} ${stylesApp.cardMessageBalloon} ${styles.cardMessageBalloon}`}  style={{ backgroundColor: baseColor }}>
          <div className={styles.dot} style={{ color: foregroundColor }}></div>
          <div className={styles.dot} style={{ color: foregroundColor }}></div>
          <div className={styles.dot} style={{ color: foregroundColor }}></div>
        </div>
      </div>
    </div>
  );
};

export default Typing;
