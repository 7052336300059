import foregroundColor from './foregroundColor';
import { Buffer } from 'buffer';
import requester from '../services/requester';

export let chatConfig;

export const setPreviewPage = previewPage => {
  chatConfig.previewPage = previewPage;
};

export const setQueueMessage = messageQueue => {
  chatConfig.messageQueue = messageQueue;
};

export const setTermsOfUse = termsOfUse => {
  chatConfig.termsOfUse = termsOfUse;
};

export const setTermsOfUseId = termsOfUseId => {
  chatConfig.termsOfUseId = termsOfUseId;
};

export const setTermsOfUseTitle = termsOfUseTitle => {
  chatConfig.termsOfUseTitle = termsOfUseTitle;
};

export const setTermsOfUseType = termsOfUseType => {
  chatConfig.termsOfUseType = termsOfUseType;
};

export const setHasTermsOfUse = hasTermsOfUse => {
  chatConfig.hasTermsOfUse = hasTermsOfUse;
};

export const setBaseColor = baseColor => {
  chatConfig.styles.baseColor = baseColor || '#aa6ae5';
  chatConfig.styles.foregroundColor = foregroundColor(chatConfig.styles.baseColor);
};

export const setTitle = title => {
  chatConfig.styles.title = title;
};

export const setName = name => {
  chatConfig.styles.name = name || '';
};

export const setDescription = description => {
  chatConfig.styles.description = description || '';
};

export const setImg192 = img192 => {
  chatConfig.styles.img192 = img192 || `https://builder4bots-homolog.s3.amazonaws.com/2020/2/5e679725bd1db1357de792d7/5fc96b8d-97fb-42d7-9318-19cff3e23e01.png`;
};

export const setImg512 = img512 => {
  chatConfig.styles.img512 = img512 || 'https://i.ibb.co/v4TKWk2/newway-512.png';
};

export const setFooterText = footerText => {
  chatConfig.styles.footerText = footerText || 'Powered by New Way';
};

export const setFooterUrl = footerUrl => {
  chatConfig.styles.footerUrl = footerUrl || '';
};

const removeUndefinedKeys = (obj) => {
  const newObj = {};
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        newObj[key] = obj[key];
      }
    });
  }

  return newObj;
};

export const setChatConfigs = configs => {
  // FIXME: Aguardando DIMI atualizar.
  if (!configs.styles) {
    const newStyles = removeUndefinedKeys(configs);

    chatConfig = {
      styles: Object.assign({}, getDefaultConfig().styles, newStyles),
      form: null,
      // form: {
      //   fields: [],
      //   showFormMessage: true,
      //   submitted: false
      // },
      showTyping: true,
      ...getChatterConfig(),
      ...getDisplayConfig(),
      ready: true,
    } || {};

  } else {
    const newStyles = removeUndefinedKeys(configs.styles);
    const newForm = removeUndefinedKeys(configs.form);

    chatConfig = {
      ...configs,
      styles: Object.assign({}, getDefaultConfig().styles, newStyles),
      form: Object.assign({}, getDefaultConfig().form, newForm),
      ...getChatterConfig(),
      ...getDisplayConfig(),
      ready: true,
    } || {};
  }

  if (chatConfig.styles) {
    chatConfig.styles.foregroundColor = foregroundColor(chatConfig.styles.baseColor);
  }

  if (!chatConfig.form) {
    chatConfig.form = {
      fields: null
    };
  }

  if (!chatConfig.form.fields || !chatConfig.form.fields.length) {
    chatConfig.form.fields = null;
  }

  window.parent.postMessage({ ready: true }, '*');
};

const queryParams = new URLSearchParams(window.location.search);
const bufferDecode = queryParams.get('config') ? Buffer.from(queryParams.get('config'), 'base64') : null;
const jsonDecoded = bufferDecode ? JSON.parse(bufferDecode.toString()) : {};

const getChatterConfig = () => {
  if (queryParams.get('display') === 'draw') {
    return {
      chatter: {
        channelId: '',
        token: '',
      }
    };
  }

  return {
    chatter: {
      channelId: jsonDecoded.channelId || '',
      token: jsonDecoded.token || '',
    }
  };
};

const getDisplayConfig = () => {
  return {
    isWidget: queryParams.get('display') === 'widget',
    isDraw: queryParams.get('display') === 'draw',
  };
};

const getDefaultConfig = () => {
  return {
    styles: {
      title: '',
      name: '',
      description: '',
      footerText: 'Powered by New Way',
      img192: `https://builder4bots-homolog.s3.amazonaws.com/2020/2/5e679725bd1db1357de792d7/5fc96b8d-97fb-42d7-9318-19cff3e23e01.png`,
      img512: `https://i.ibb.co/v4TKWk2/newway-512.png`,
      baseColor: '#aa6ae5',
      foregroundColor: foregroundColor('#aa6ae5'),
    },
    form : {
      fields: [],
      showFormMessage: true,
      submitted: false
    },
    ...getChatterConfig(),
    ...getDisplayConfig()
  };
};

if (queryParams.get('display') === 'draw') {
  setChatConfigs(getDefaultConfig());
}

if (!queryParams.get('display') || queryParams.get('display') === 'page') {
  // FIXME: Aguardando DIMI atualizar.
  if (!jsonDecoded.url) {
    setTimeout(() => {
      const { updateChatConfigState } = require('../services/postMessage');

      updateChatConfigState({
        ...jsonDecoded,
        ...getChatterConfig(),
        ...getDisplayConfig(),
      });
    }, 1000);
  } else {
    requester.get(jsonDecoded.url).then(({ data }) => {
      const { updateChatConfigState } = require('../services/postMessage');
      updateChatConfigState({
        ...data,
        ...getChatterConfig(),
        ...getDisplayConfig(),
      });
    })
    .catch(() => {
      const { updateChatConfigState, fetchingConfigErrorHandler } = require('../services/postMessage');
      const errorColors = { baseColor: '#909399', foregroundColor: foregroundColor('#909399') };

      updateChatConfigState({
        ...getDefaultConfig(),
        styles: Object.assign({}, getDefaultConfig().styles, errorColors),
        ...getChatterConfig(),
        ...getDisplayConfig(),
      });
      fetchingConfigErrorHandler();
    });
  }
}

export const getConversationStartHash = (form) => {
  try {
    return `${chatConfig.chatter.channelId}_START${form ? '|' + JSON.stringify(form) : ''}`;
  } catch (error) {
    console.error(error);
    return `${chatConfig.chatter.channelId}_START`;
  }
};

export const getConversationFinalizationHash = () => {
  return `${chatConfig.chatter.channelId}_CANCEL`;
};

export default chatConfig || getDefaultConfig();
