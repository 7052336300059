import { HANDLER_VISIBLE_CHAT, HANDLER_LOADING, SHOW_DIALOG, HIDE_DIALOG } from '../actions/actionTypes';

const APP_STATE = {
  showApp: true,
  isLoading: true,
  dialogData: {
    show: false,
    title: 'Título',
    content: 'Descrição',
    buttons: [
      {
        type: 'basic',
        label: 'Cancelar',
        onClick: () => {}
      },
      {
        type: 'primary',
        label: 'Confirmar',
        onClick: () => {}
      }
    ]
  }
};

export const appReducer = (state = APP_STATE, action) => {
  switch (action.type) {
    case HANDLER_VISIBLE_CHAT:
      return {
        ...state,
        showApp: action.showApp,
      };
    case HANDLER_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case SHOW_DIALOG:
      return {
        ...state,
        dialogData: {
          show: true,
          ...action.dialogData
        }
      };
    case HIDE_DIALOG:
      return {
        ...state,
        dialogData: {
          show: false
        }
      };
    default:
      return state;
  }
};
