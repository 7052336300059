import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { handlerChat, showDialog } from '../../../store/actions/appActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faTimesCircle, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import GlobalTriggerButton from './GlobalTriggerButton';
import { disconnectChatter } from '../../../services/chatter';
import { showChat, showTerms, finalizeChat } from '../../../store/actions/chatActions';
import { resetForm } from '../../../store/actions/configActions';

import { MESSAGE_TYPE_FINALIZATION, MESSAGE_TYPE_FINISHED } from '../../../constants';

import styles from './styles.module.css';
import { clearCacheFormMetadata } from '../../../services/formFields';

const Header = () => {
  const dispatch = useDispatch();
  const title = useSelector(state => state.configState.styles.title);
  const baseColor = useSelector(state => state.configState.styles.baseColor);
  const foregroundColor = useSelector(state => state.configState.styles.foregroundColor);
  const isWidget = useSelector(state => state.configState.isWidget);
  const messageType = useSelector(state => state.chatState.messageType);
  const messages = useSelector(state => state.chatState.messages);
  const globalTriggers = useSelector(state => state.configState.globalTriggers || []);
  const isDraw = useSelector(state => state.configState.isDraw);
  const showTermsState = useSelector(state => state.chatState.showTerms);

  const styleHeader = { backgroundColor: baseColor };
  let headerClass = '';

  if (isWidget || isDraw) {
    headerClass = `${styles.headerBorderRadius}`;
  }

  const headerClasses = `${styles.header} ${headerClass}`;

  const headerSvgLogoName = foregroundColor === '#FFFFFF' ? 'icon-white' : 'icon-black';

  const hideWidgetFrame = () => {
    window.parent.postMessage({ hideFrame: true }, '*');
  };

  const dialogData = {
    title: 'Confirmação',
    content: 'Tem certeza de que deseja finalizar o atendimento?',
    buttons: [
      {
        type: 'basic',
        label: 'Não',
      },
      {
        type: 'primary',
        label: 'Sim',
        onClick: async () => {
          dispatch(finalizeChat());
          dispatch(resetForm());

          if (messageType !== MESSAGE_TYPE_FINALIZATION) {
            disconnectChatter();
            clearCacheFormMetadata();

            if (isWidget) {
              hideWidgetFrame();
            }

            dispatch(showChat(false));
            dispatch(showTerms(false));
          }
        }
      }
    ]
  };

  const renderHeaderButtons = () => {
    const buttons = [];

    if (isWidget || isDraw) {
      buttons.push(<Button
        key="headerMinimizeButton"
        onClick={() => { hideWidgetFrame(); dispatch(handlerChat(false)); }}
        icon={<FontAwesomeIcon icon={faMinusCircle} />}
        color={foregroundColor}
      />);
    }

    if (messages.length > 1 && messageType !== MESSAGE_TYPE_FINALIZATION && messageType !== MESSAGE_TYPE_FINISHED) {
      if (globalTriggers.length > 0) {
        buttons.push(<GlobalTriggerButton
          key="headerDropdownButton"
          onExitButton={() => { !isDraw && dispatch(showDialog(dialogData)); }}
          icon={<FontAwesomeIcon icon={faBars} />}
        />);
      } else {
        buttons.push(<Button
          key="headerExitButton"
          onClick={() => { !isDraw && dispatch(showDialog(dialogData)); }}
          icon={<FontAwesomeIcon icon={isWidget || isDraw ? faTimesCircle : faSignOutAlt} />}
          color={foregroundColor}
        />);
      }
    } else {
      if (showTermsState) {
        buttons.push(<Button
          key="headerExitButton"
          onClick={() => { !isDraw && dispatch(showTerms(false)); }}
          icon={<FontAwesomeIcon icon={isWidget || isDraw ? faTimesCircle : faSignOutAlt} />}
          color={foregroundColor}
        />);
      }
    }

    return buttons;
  };

  return (
    <header className={headerClasses} style={styleHeader}>
      <div className={styles.headerContent}>
        { !title ?
          <img src={require(`../../../assets/icons/${headerSvgLogoName}.svg`)} className={styles.headerSvgLogo}  alt={headerSvgLogoName} /> :
          <span className={styles.headerTitle} style={{color: foregroundColor}}> { title } </span>
        }
        <div className={styles.headerContentButtons}>
          { renderHeaderButtons().map(button => button)}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
