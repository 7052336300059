import React from 'react';
import PropTypes from 'prop-types';
import pdfLogo from '../../../../../assets/icons/pdf-file-icon.png';

import styles from './styles.module.css';

const CardImage = props => {
  return (
    <div className={styles.imageCard}>
      {
        props.images.map((image, index) => {
          if (image.type === 'video/mp4') {
            return <video className={props.animationClasses ? "animated fadeInUp delay-1s" : ""}
              src={image.url}
              key={index}
              alt={index}
              controls />;
          }

          if (image.type === 'application/pdf') {
            return (
              <a href={image.url} target="blank" key={index}>
                <div className={styles.pdfLogoWithLink}>
                  <img
                    className={props.animationClasses ? "animated fadeInUp delay-1s" : ""}
                    src={pdfLogo}
                    alt={index} />
                  <span>Download</span>
                </div>
              </a>
            );
          }

          if (image.type && image.type.indexOf('image') !== -1) {
            return <div className={props.animationClasses} key={index}>
              <a href={image.url} target="_blank" rel="noopener noreferrer">
                <img src={image.url} alt={image.url} />
              </a>
            </div>;
          }

          return <div className={props.animationClasses} key={index}>
            <a href={image.url} target="_blank" rel="noopener noreferrer">
              {image.url}
            </a>
          </div>;
        })
      }
    </div>
  );
};

CardImage.propTypes = {
  images: PropTypes.array,
  animationClasses: PropTypes.string,
};

export default React.memo(CardImage);
