export const i18n = {
  search: 'Pesquisar',
  clear: 'Limpar',
  notfound: 'Nenhum emoji encontrado',
  skintext: 'Escolha seu tom de pele padrão',
  categories: {
    search: 'Resultados da pesquisa',
    recent: 'Recentes',
    people: 'Caras e pessoas',
    nature: 'Animais e natureza',
    foods: 'Comida e bebida',
    activity: 'Atividades',
    places: 'Viagens e lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Personalizado',
  },
  categorieslabel: 'Categorias',
  skintones: {
    1: 'Default Skin Tone',
    2: 'Light Skin Tone',
    3: 'Medium-Light Skin Tone',
    4: 'Medium Skin Tone',
    5: 'Medium-Dark Skin Tone',
    6: 'Dark Skin Tone',
  },
};

export const emojisToShowFilter = (emoji) => {
  return emoji;
};
