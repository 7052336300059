import chatter from '@newway/chatter-sdk';
import newchat from '@newway/newchat-sdk';

import chatConfig from './chatConfig';
import { CONVERSATION_HISTORY_AMOUNT } from '../constants';
import store from '../store';
import { handlerLoading } from '../store/actions/appActions';

import requester from './requester';
import { normalizeMessage } from './normalizer';
import { sendNotification } from './browserNotification';
import { MESSAGE_TYPE_FINISHED } from '../constants';
import {
  showChat,
  showTerms,
  messageReceived,
  sendMessage,
  showSystemTyping,
  startChat,
  finishChat,
} from '../store/actions/chatActions';
import { updateTermsOfUse, updateTermsOfUseId, updateTermsOfUseTitle, updateTermsOfUseType } from '../store/actions/configActions';
import { getBaseUrl, getChannelId } from './urlConfig';
import { getFormMetadataFromCached, setFormMetadataInCache } from './formFields';

let chatterInstance = null;
let bareJid = null;

const chatterConfigs = {
  channelId: chatConfig.chatter.channelId,
  token: chatConfig.chatter.token,
};

const getChatterInstance = async () => {
  if (chatterInstance) {
    return chatterInstance;
  }

  let _chatter;
  const { data } = await requester.get(`${process.env.REACT_APP_CHATTER_URL}/settings/${chatterConfigs.channelId}`);

  chatterConfigs.url = data.settings.url;

  if (data.settings.type === 'newchat') {
    _chatter = newchat;

    chatterConfigs.websocket = data.settings.websocket;
    chatterConfigs.storage = data.settings.storage;
  } else {
    _chatter = chatter;
  }

  return await _chatter.initialize(chatterConfigs)
    .then(instance => {
      store.dispatch(handlerLoading(true));
      chatterInstance = instance;
      chatterInstance.channelType = data.settings.type || 'chatter';
      return chatterInstance;
    })
    .catch(err => console.log(err));
};

export const loadConversationHistory = (fromDate) => {
  const params = {
    bareJid: bareJid,
    pageSize: CONVERSATION_HISTORY_AMOUNT
  };
  if (fromDate) {
    params.beforeTimestamp = fromDate;
    chatterInstance.getHistory(params);
  } else {
    chatterInstance.getHistory(params);
  }
};

export const connectChatter = async (formFields, welcomeMessage) => {
  const baseUrl = getBaseUrl();
  let formJson = {};
  
  if (formFields) {
    formFields.forEach(field => {
      formJson[field.name] = field.value;
    });
  }

  let retornoRequestTermsOfUse = await requester.get(`${baseUrl}/api/v1/history-terms/${getChannelId()}/`, {
    params: formJson,
  });

  const termsOfUse = retornoRequestTermsOfUse.data;

  setFormMetadataInCache(formFields, welcomeMessage);

  if (termsOfUse.exists && !termsOfUse.accepted) {
    store.dispatch(updateTermsOfUseId(termsOfUse.channelsDataId));
    store.dispatch(updateTermsOfUseType(termsOfUse.termsOfUseType));
    store.dispatch(updateTermsOfUseTitle(termsOfUse.termsOfUseTitle));
    store.dispatch(updateTermsOfUse(termsOfUse.terms));
    store.dispatch(showTerms(true));

    return;
  }

  chatterInstance = await getChatterInstance();

  if (chatterInstance.channelType === 'newchat') {
    chatterInstance
      .on(eventsChatter.ONLINE, async () => {
        store.dispatch(startChat(formJson));
        if (welcomeMessage) {
          store.dispatch(sendMessage(welcomeMessage));
        }
        store.dispatch(showChat(true));
        store.dispatch(handlerLoading(false));
      });
  } else {
    chatterInstance
      .on(eventsChatter.ONLINE, async () => {
        await chatterInstance.getRoster();
      })
      .on(eventsChatter.ROSTER, roster => {
        if (roster && roster[0]) {
          bareJid = roster[0].bareJid;
        }

        store.dispatch(startChat(formJson));
        if (welcomeMessage) {
          store.dispatch(sendMessage(welcomeMessage));
        }
        store.dispatch(showChat(true));
        store.dispatch(handlerLoading(false));
      });
  }

  chatterInstance
    .on(eventsChatter.CHAT_MESSAGE, ({ body, sentAt }) => {
      const messages = JSON.parse(body);

      messages.forEach((message, index) => {
        const newMessage = normalizeMessage(message);

        if (newMessage.content) {
          store.dispatch(messageReceived(Object.assign({}, newMessage, { sentAt })));
        }

        if (index === messages.length - 1) {
          if (message.type === MESSAGE_TYPE_FINISHED) {
            if (chatterInstance) {
              disconnectChatter();
            }
            store.dispatch(finishChat());
          }
        }
      });

      store.dispatch(showSystemTyping(false));

      sendNotification(messages[0].content);
    })
    .on(eventsChatter.CHAT_STATE, message => {
      if (message.state === statesChatter.COMPOSING) {
        store.dispatch(showSystemTyping(true));
      }
    });

  const credentials = await chatterInstance.getNewCredentials();
  credentials.channelId = chatterConfigs.channelId;
  credentials.server = chatterConfigs.url;

  chatterInstance.connect(credentials);
};

export const disconnectChatter = () => {
  if (chatterInstance) {
    chatterInstance.disconnect();
    chatterInstance = null;
  }
};

export const sendMessageChatter = async (message) => {
  if (chatterInstance) {
    let messageSend = {
      message:message,
    };

    messageSend = {...messageSend, ...getFormMetadataFromCached()}

    chatterInstance
      .sendText({
        to: bareJid, // newchat-sdk não lê mais isso
        text: messageSend
      });
  }
};

export const sendAttachmentChatter = async (file) => {

  let formMetadata = getFormMetadataFromCached();

  if (formMetadata) {
    Object.defineProperty(file, 'name', {
      value: formMetadata.name,
      writable: false
    });
  
    Object.defineProperty(file, 'phone', {
      value: formMetadata.phone,
      writable: false
    });
  }

  return await chatterInstance
    .uploadAndSendAttachment({
      to: bareJid, // newchat-sdk não lê mais isso
      file
    });
};

// Só o chatter-sdk exporta esse `presence`
export const isChatterOnline = () => chatterInstance ? chatterInstance.presence === 'online' : false;
export const eventsChatter = chatter.events;
export const statesChatter = chatter.CHAT_STATES;
