import React from 'react';

import styles from './styles.module.css';

const FetchingErrorMessage = () => {
  return (
    <div className={`${styles.fetchingErrorMessage}`}>
      <p>Houve um erro durante o carregamento. Tente novamente mais tarde.</p>
    </div>
  );
};

export default FetchingErrorMessage;
