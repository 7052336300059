import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { sendMessage, handleMessageInputChange } from '../../../../store/actions/chatActions';

import ButtonImage from '../../../layout/Base/Buttons/Image/index';

import styles from './styles.module.css';

const UserInputMessage = props => {
  const dispatch = useDispatch();
  const messageToSend = useSelector(state => state.chatState.messageToSend);

  function sendMessageByEnterKey(e) {
    if (messageToSend.length > 0 && e.key === 'Enter') {
      dispatch(sendMessage(messageToSend));
    }
  }

  function handlerMessageToSend() {
    if (messageToSend) {
      dispatch(sendMessage(messageToSend));
    }
  }

  return (
    <React.Fragment>
      <section className={styles.userInputMessage} >
        <input
          ref={props.inputRef}
          type="text"
          className={styles.userInputMessageInput}
          name="messageToSend"
          placeholder="Digite aqui"
          autoComplete="off"
          onKeyPress={sendMessageByEnterKey}
          onChange={e => dispatch(handleMessageInputChange(e))}
          value={messageToSend}
        />

        <ButtonImage
          className={styles.sendMessage}
          imageName="send"
          disabled={!!(messageToSend) && messageToSend.length === 0}
          onClick={handlerMessageToSend}
        />
      </section>
    </React.Fragment>
  );
};

UserInputMessage.propTypes = {
  inputRef: PropTypes.object,
};

export default React.memo(UserInputMessage);
