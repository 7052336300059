import React from 'react';
import { useSelector } from 'react-redux';

import Form from '../Form';
import StartButton from '../Chat/Conversation/StartButton';

import styles from './styles.module.css';

function Welcome() {
  const form = useSelector(state => state.configState.form);
  const showForm = form && form.fields;

  return (
    <div className={ styles.container }>
      { showForm && <Form /> }
      <StartButton />
    </div>
  );
}

export default Welcome;
