import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectChatter, disconnectChatter } from "../../services/chatter";
import { clearMessages } from "../../store/actions/chatActions";
import requester from "../../services/requester";

import styles from "./styles.module.css";
import { getBaseUrl } from "../../services/urlConfig";
import { TERMS_OF_USE_TYPE_FILE, TERMS_OF_USE_TYPE_TEXT } from "../../domain/TermsOfUse/type";
import { getFormFieldsFormCached } from "../../services/formFields";

function TermsOfUse() {
  const dispatch = useDispatch();
  const isDraw = useSelector((state) => state.configState.isDraw);
  const termsOfUseType = useSelector((state) => state.configState.termsOfUseType);
  const termsOfUseId = useSelector((state) => state.configState.termsOfUseId);
  const termsOfUseTitle = useSelector((state) => state.configState.termsOfUseTitle);
  const termsOfUse = useSelector((state) => state.configState.termsOfUse);
  const baseColor = useSelector((state) => state.configState.styles.baseColor);

  const [termsOfUseAccept, setTermsOfUseAccept] = useState(false);

  const handleStartButton = async () => {
    if (isDraw) {
      return;
    }

    if (!document.querySelectorAll('input[name="termsOfUseAccept"]')[0].checked) {
      return;
    }

    let connectChatterParams = getFormFieldsFormCached();

    const baseUrl = getBaseUrl();

    let retornoAceiteTermos = await requester.post(`${baseUrl}/api/v1/history-terms`, {
      name: connectChatterParams.formFields.find((value) => value.name === "name").value,
      phone: connectChatterParams.formFields.find((value) => value.name === "phone").value,
      channels_data_id: termsOfUseId,
    });

    const aceiteTermos = retornoAceiteTermos.data;

    if (aceiteTermos) {
      await connectChatter(connectChatterParams.formFields, connectChatterParams.welcomeMessage);
    }
  };

  const renderTermsOfUse = () => {
    if (termsOfUseType === TERMS_OF_USE_TYPE_FILE) {
      return (
        <>
          <p className={styles.termsOfUseTitle}>{termsOfUseTitle}</p>
          <a className={styles.termsOfUseViewFileButton} href={termsOfUse} target="_blank">
            Visualizar
          </a>
        </>
      );
    }

    if (termsOfUseType == TERMS_OF_USE_TYPE_TEXT) {
      return (
        <div className={styles.card}>
          <div className={styles.termsOfUse}>
            <h3>Termos de uso</h3>

            {termsOfUse}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    return () => {
      if (isDraw) {
        dispatch(clearMessages());
      }
    };
  }, [dispatch, isDraw, termsOfUse]);

  return (
    <div className={styles.container}>

      {renderTermsOfUse()}

      <div className={styles.checkbox}>
        <label htmlFor="check"></label>
        <input
          id="check"
          type="checkbox"
          name="termsOfUseAccept"
          checked={termsOfUseAccept}
          onChange={(event) => {
            setTermsOfUseAccept(!termsOfUseAccept);
          }}
        />

        <small>Concordo com os termos</small>
      </div>

      <div className={styles.startButton}>
        <button
          onClick={handleStartButton}
          style={{ color: baseColor, opacity: termsOfUseAccept ? 1 : 0.4 }}
          disabled={!termsOfUseAccept}
        >
          Iniciar conversa
        </button>
      </div>
    </div>
  );
}

export default TermsOfUse;
